import * as i0 from '@angular/core';
import { InjectionToken, makeEnvironmentProviders, NgModule, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { of, throwError, Observable, asyncScheduler, from, ReplaySubject, fromEvent, race } from 'rxjs';
import { observeOn, mergeMap, map, first, takeWhile, tap, catchError } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

/**
 * Exception message when `indexedDB` is not working
 */
const IDB_BROKEN_ERROR = "indexedDB is not working";
/**
 * Exception raised when `indexedDB` is not working
 */
class IDBBrokenError extends Error {
  constructor() {
    super(...arguments);
    this.message = IDB_BROKEN_ERROR;
  }
}
/**
 * Exception message when a value cannot be serialized for `localStorage`
 */
const SERIALIZATION_ERROR = `The storage is currently localStorage,
where data must be serialized, and the provided data can't be serialized.`;
/**
 * Exception throwned when a value cannot be serialized for `localStorage`
 */
class SerializationError extends Error {
  constructor() {
    super(...arguments);
    this.message = SERIALIZATION_ERROR;
  }
}

/**
 * Token to provide a prefix to `localStorage` keys.
 */
const LS_PREFIX = new InjectionToken("localStoragePrefix", {
  providedIn: "root",
  factory: () => ""
});
/**
 * Default name used for `indexedDB` database.
 */
const DEFAULT_IDB_DB_NAME = "ngStorage";
/**
 * Token to provide `indexedDB` database name.
 */
const IDB_DB_NAME = new InjectionToken("localStorageIDBDBName", {
  providedIn: "root",
  factory: () => DEFAULT_IDB_DB_NAME
});
/**
 * Default version used for `indexedDB` database.
 */
const DEFAULT_IDB_DB_VERSION = 1;
/**
 * Token to provide `indexedDB` database version.
 * Must be an unsigned **integer**.
 */
const IDB_DB_VERSION = new InjectionToken("localStorageIDBDBVersion", {
  providedIn: "root",
  factory: () => DEFAULT_IDB_DB_VERSION
});
/**
 * Default name used for `indexedDB` object store.
 */
const DEFAULT_IDB_STORE_NAME = "localStorage";
/**
 * Token to provide `indexedDB` store name.
 * For backward compatibility, the default can't be set now, `IndexedDBDatabase` will do it at runtime.
 */
const IDB_STORE_NAME = new InjectionToken("localStorageIDBStoreName", {
  providedIn: "root",
  factory: () => DEFAULT_IDB_STORE_NAME
});
/**
 * Default value for interoperability with native `indexedDB` and other storage libs,
 * by changing how values are stored in `indexedDB` database.
 */
const DEFAULT_IDB_NO_WRAP = true;
/**
 * Token to allow interoperability with native `indexedDB` and other storage libs,
 * by changing how values are stored in `indexedDB` database.
 * Defaults to `true`. Change to `false` for backward compatiblity in existing applications.
 * **DO NOT CHANGE THIS BEHAVIOR ONCE IN PRODUCTION**, as it would break with existing data.
 */
const IDB_NO_WRAP = new InjectionToken("localStorageIDBWrap", {
  providedIn: "root",
  factory: () => DEFAULT_IDB_NO_WRAP
});

/**
 * Allows to add a prefix before `localStorage` keys.
 *
 * *Use only* for interoperability with other APIs or to avoid collision for multiple applications on the same subdomain.
 *
 * **WARNING: do not change this option in an application already deployed in production, as previously stored data would be lost.**
 *
 * @example
 * export const appConfig: ApplicationConfig = {
 *   providers: [provideLocalStoragePrefix('custom_')]
 * };
 */
function provideLocalStoragePrefix(prefix) {
  return makeEnvironmentProviders([{
    provide: LS_PREFIX,
    useValue: prefix
  }]);
}
/**
 * Allows to change the name used for `indexedDB` database.
 *
 * *Use only* for interoperability with other APIs or to avoid collision for multiple applications on the same subdomain.
 *
 * **WARNING: do not change this option in an application already deployed in production, as previously stored data would be lost.**
 *
 * @example
 * export const appConfig: ApplicationConfig = {
 *   providers: [provideIndexedDBDataBaseName('custom')]
 * };
 */
function provideIndexedDBDataBaseName(name) {
  return makeEnvironmentProviders([{
    provide: IDB_DB_NAME,
    useValue: name
  }]);
}
/**
 * Allows to change the database version used for `indexedDB` database.
 * Must be an unsigned **integer**.
 *
 * **Use with caution as the creation of the store depends on the version.**
 *
 * *Use only* for interoperability with other APIs or to avoid collision for multiple applications on the same subdomain.
 *
 * **WARNING: do not change this option in an applicattion already deployed in production, as previously stored data would be lost.**
 *
 * @example
 * export const appConfig: ApplicationConfig = {
 *   providers: [provideIndexedDBDataBaseVersion(2)]
 * };
 */
function provideIndexedDBDataBaseVersion(version) {
  return makeEnvironmentProviders([{
    provide: IDB_DB_VERSION,
    useValue: version
  }]);
}
/**
 * Allows to change the name used for `indexedDB` object store.
 *
 * *Use only* for interoperability with other APIs.
 *
 * **WARNING: do not change this option in an application already deployed in production, as previously stored data would be lost.**
 *
 * @example
 * export const appConfig: ApplicationConfig = {
 *   providers: [provideIndexedDBStoreName('custom')]
 * };
 */
function provideIndexedDBStoreName(name) {
  return makeEnvironmentProviders([{
    provide: IDB_STORE_NAME,
    useValue: name
  }]);
}

/**
 * This module is only here for backward compatibility, **do not add it by yourself**
 *
 * @ignore
 */
class StorageModule {
  /**
   * Only useful to provide options, otherwise it does nothing.
   *
   * **Must be used at initialization, ie. in `AppModule`, and must not be loaded again in another module.**
   */
  static forRoot(config) {
    return {
      ngModule: StorageModule,
      providers: [config.LSPrefix ? {
        provide: LS_PREFIX,
        useValue: config.LSPrefix
      } : [], config.IDBDBName ? {
        provide: IDB_DB_NAME,
        useValue: config.IDBDBName
      } : [], config.IDBStoreName ? {
        provide: IDB_STORE_NAME,
        useValue: config.IDBStoreName
      } : [], config.IDBDBVersion ? {
        provide: IDB_DB_VERSION,
        useValue: config.IDBDBVersion
      } : [], config.IDBNoWrap === false ? {
        provide: IDB_NO_WRAP,
        useValue: config.IDBNoWrap
      } : []]
    };
  }
  static {
    this.ɵfac = function StorageModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StorageModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: StorageModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StorageModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Exception message when a value is not valid against the JSON schema
 */
const VALIDATION_ERROR = `Data stored is not valid against the provided JSON schema.
Check your JSON schema, otherwise it means data has been corrupted.`;
/**
 * Exception throwned when a value is not valid against the JSON schema
 */
class ValidationError extends Error {
  constructor() {
    super(...arguments);
    this.message = VALIDATION_ERROR;
  }
}
class LocalStorageDatabase {
  constructor() {
    /* Prefix if asked, or no prefix otherwise */
    this.prefix = inject(LS_PREFIX) || "";
  }
  /**
   * Number of items in `localStorage`
   */
  get size() {
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(localStorage.length);
  }
  /**
   * Gets an item value in `localStorage`
   * @param key The item's key
   * @returns The item's value if the key exists, `undefined` otherwise, wrapped in a RxJS `Observable`
   */
  get(key) {
    /* Get raw data */
    const unparsedData = localStorage.getItem(this.prefixKey(key));
    /* No need to parse if data is `null` or `undefined` */
    if (unparsedData !== null) {
      /* Try to parse */
      try {
        const parsedData = JSON.parse(unparsedData);
        /* Wrap in a RxJS `Observable` to be consistent with other storages */
        return of(parsedData);
      } catch (error) {
        return throwError(() => error);
      }
    }
    return of(undefined);
  }
  /**
   * Store an item in `localStorage`
   * @param key The item's key
   * @param data The item's value
   * @returns A RxJS `Observable` to wait the end of the operation
   */
  set(key, data) {
    let serializedData = null;
    /* Check if data can be serialized */
    const dataPrototype = Object.getPrototypeOf(data);
    if (typeof data === "object" && data !== null && !Array.isArray(data) && !(dataPrototype === Object.prototype || dataPrototype === null)) {
      return throwError(() => new SerializationError());
    }
    /* Try to stringify (can fail on circular references) */
    try {
      serializedData = JSON.stringify(data);
    } catch (error) {
      return throwError(() => error);
    }
    /* Can fail if storage quota is exceeded */
    try {
      localStorage.setItem(this.prefixKey(key), serializedData);
    } catch (error) {
      return throwError(() => error);
    }
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(undefined);
  }
  /**
   * Deletes an item in `localStorage`
   * @param key The item's key
   * @returns A RxJS `Observable` to wait the end of the operation
   */
  delete(key) {
    localStorage.removeItem(this.prefixKey(key));
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(undefined);
  }
  /**
   * Deletes all items in `localStorage`
   * @returns A RxJS `Observable` to wait the end of the operation
   */
  clear() {
    localStorage.clear();
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(undefined);
  }
  /**
   * Get all keys in `localStorage`
   * Note the order of the keys may be inconsistent in Firefox
   * @returns A RxJS `Observable` iterating on keys
   */
  keys() {
    /* Create an `Observable` from keys */
    return new Observable(subscriber => {
      /* Iteretate over all the indexes */
      for (let index = 0; index < localStorage.length; index += 1) {
        /* Cast as we are sure in this case the key is not `null` */
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Ensured by the logic
        subscriber.next(this.getUnprefixedKey(index));
      }
      subscriber.complete();
    }).pipe( /* Required to work like other databases which are asynchronous */
    observeOn(asyncScheduler));
  }
  /**
   * Check if a key exists in `localStorage`
   * @param key The item's key
   * @returns A RxJS `Observable` telling if the key exists or not
   */
  has(key) {
    /* Itérate over all indexes in storage */
    for (let index = 0; index < localStorage.length; index += 1) {
      if (key === this.getUnprefixedKey(index)) {
        /* Wrap in a RxJS `Observable` to be consistent with other storages */
        return of(true);
      }
    }
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(false);
  }
  /**
   * Get an unprefixed key
   * @param index Index of the key
   * @returns The unprefixed key name if exists, `null` otherwise
   */
  getUnprefixedKey(index) {
    /* Get the key in storage: may have a prefix */
    const prefixedKey = localStorage.key(index);
    if (prefixedKey !== null) {
      /* If no prefix, the key is already good, otherwrite strip the prefix */
      return !this.prefix ? prefixedKey : prefixedKey.substring(this.prefix.length);
    }
    return null;
  }
  /**
   * Add the prefix to a key
   * @param key The key name
   * @returns The prefixed key name
   */
  prefixKey(key) {
    return `${this.prefix}${key}`;
  }
  static {
    this.ɵfac = function LocalStorageDatabase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalStorageDatabase)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageDatabase,
      factory: LocalStorageDatabase.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageDatabase, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
class MemoryDatabase {
  constructor() {
    /**
     * Memory storage
     */
    this.memoryStorage = new Map();
  }
  /**
   * Number of items in memory
   */
  get size() {
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(this.memoryStorage.size);
  }
  /**
   * Gets an item value in memory
   * @param key The item's key
   * @returns The item's value if the key exists, `undefined` otherwise, wrapped in a RxJS `Observable`
   */
  get(key) {
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(this.memoryStorage.get(key));
  }
  /**
   * Sets an item in memory
   * @param key The item's key
   * @param data The item's value
   * @returns A RxJS `Observable` to wait the end of the operation
   */
  set(key, data) {
    this.memoryStorage.set(key, data);
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(undefined);
  }
  /**
   * Deletes an item in memory
   * @param key The item's key
   * @returns A RxJS `Observable` to wait the end of the operation
   */
  delete(key) {
    this.memoryStorage.delete(key);
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(undefined);
  }
  /**
   * Deletes all items in memory
   * @returns A RxJS `Observable` to wait the end of the operation
   */
  clear() {
    this.memoryStorage.clear();
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(undefined);
  }
  /**
   * Get all keys in memory
   * @returns A RxJS `Observable` iterating on keys
   */
  keys() {
    /* Create an `Observable` from keys */
    return from(this.memoryStorage.keys());
  }
  /**
   * Check if a key exists in memory
   * @param key Key name
   * @returns a RxJS `Observable` telling if the key exists or not
   */
  has(key) {
    /* Wrap in a RxJS `Observable` to be consistent with other storages */
    return of(this.memoryStorage.has(key));
  }
  static {
    this.ɵfac = function MemoryDatabase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MemoryDatabase)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MemoryDatabase,
      factory: MemoryDatabase.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MemoryDatabase, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], null, null);
})();

/**
 * Factory to create a storage according to browser support
 * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/BROWSERS_SUPPORT.md}
 */
function localDatabaseFactory() {
  const platformId = inject(PLATFORM_ID);
  /* When storage is fully disabled in browser (via the "Block all cookies" option),
   * just trying to check `indexedDB` or `localStorage` variables causes a security exception.
   * Prevents https://github.com/cyrilletuzi/angular-async-local-storage/issues/118
   */
  try {
    // Do not explicit `window` here, as the global object is not the same in web workers
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- They can be undefined in some browsers scenarios
    if (isPlatformBrowser(platformId) && indexedDB !== undefined && indexedDB !== null && "open" in indexedDB) {
      /* Check:
      * - if we are in a browser context (issue: server-side rendering)
      * - it could exist but be `undefined` or `null`
      * - it could exists but not having a working API
      * Will be the case for:
      * - All other browsers in normal mode
      * - Chromium / Safari / Firefox private mode, but in this case, data will be swiped when the user leaves the app */
      return new IndexedDBDatabase();
    } else if (isPlatformBrowser(platformId)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- They can be undefined in some browsers scenarios
    && localStorage !== undefined && localStorage !== null && "getItem" in localStorage) {
      /* Check:
      * - if we are in a browser context (issue: server-side rendering)
      * - if `localStorage` exists (to be sure)
      * - it could exists but not having a working API
      * Will be the case for:
      * - Safari cross-origin iframes, detected later in `IndexedDBDatabase.connect()`
      * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/issues/42}
      */
      return new LocalStorageDatabase();
    }
  } catch {
    // Nothing to do
  }
  /* Will be the case for:
   * - In browsers if storage has been fully disabled (via the "Block all cookies" option)
   * - Server-side rendering
   * - All other non-browser context
   */
  return new MemoryDatabase();
}
class LocalDatabase {
  static {
    this.ɵfac = function LocalDatabase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalDatabase)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalDatabase,
      factory: () => localDatabaseFactory(),
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalDatabase, [{
    type: Injectable,
    args: [{
      providedIn: "root",
      useFactory: localDatabaseFactory
    }]
  }], null, null);
})();
class IndexedDBDatabase {
  constructor() {
    /**
     * `indexedDB` database connection, wrapped in a RxJS `ReplaySubject` to be able to access the connection
     * even after the connection success event happened
     */
    this.database = new ReplaySubject(1);
    /**
     * Index used when wrapping value. *For backward compatibility only.*
     */
    this.wrapIndex = "value";
    this.dbName = inject(IDB_DB_NAME);
    this.storeName = inject(IDB_STORE_NAME);
    this.dbVersion = inject(IDB_DB_VERSION);
    this.noWrap = inject(IDB_NO_WRAP);
    /* Connect to `indexedDB`, with prefix if provided by the user */
    this.connect();
  }
  /**
   * Information about `indexedDB` connection. *Only useful for interoperability.*
   * @returns `indexedDB` database name, store name and database version
   */
  get backingStore() {
    return {
      database: this.dbName,
      store: this.storeName,
      version: this.dbVersion
    };
  }
  /**
   * Number of items in our `indexedDB` database and object store
   */
  get size() {
    /* Open a transaction in read-only mode */
    return this.transaction("readonly").pipe(mergeMap(transactionData => {
      const {
        store,
        events
      } = transactionData;
      /* Request to know the number of items */
      const request = store.count();
      /* Return the result */
      return events.pipe(map(() => request.result));
    }), /* The observable will complete after the first value */
    first());
  }
  /**
   * Gets an item value in our `indexedDB` store
   * @param key The item's key
   * @returns The item's value if the key exists, `undefined` otherwise, wrapped in an RxJS `Observable`
   */
  get(key) {
    /* Open a transaction in read-only mode */
    return this.transaction("readonly").pipe(mergeMap(transactionData => {
      const {
        store,
        events
      } = transactionData;
      /* Request the value with the key provided by the user */
      const request = store.get(key);
      /* Listen events and return the result */
      return events.pipe(map(() => {
        if (request.result !== undefined && request.result !== null) {
          /* Prior to v8, the value was wrapped in an `{ value: ...}` object */
          if (!this.noWrap && typeof request.result === "object" && this.wrapIndex in request.result &&
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Required by indexedDb behavior
          request.result[this.wrapIndex] !== undefined && request.result[this.wrapIndex] !== null) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Required by indexedDb behavior
            return request.result[this.wrapIndex];
          } else {
            /* Cast to the wanted type */
            return request.result;
          }
        }
        /* Return `undefined` if the value is empty */
        return undefined;
      }));
    }), /* The observable will complete after the first value */
    first());
  }
  /**
   * Sets an item in our `indexedDB` store
   * @param key The item's key
   * @param data The item's value
   * @returns An RxJS `Observable` to wait the end of the operation
   */
  set(key, data) {
    /* Storing `undefined` in `indexedDb` can cause issues in some browsers so removing item instead */
    if (data === undefined) {
      return this.delete(key);
    }
    /* Open a transaction in write mode */
    return this.transaction("readwrite").pipe(mergeMap(transactionData => {
      const {
        store,
        events
      } = transactionData;
      /* Prior to v8, data was wrapped in a `{ value: ... }` object */
      const dataToStore = this.noWrap ? data : {
        [this.wrapIndex]: data
      };
      /* Add if the item is not existing yet, or update otherwise */
      store.put(dataToStore, key);
      /* Listen to events and return `undefined` as no value is expected */
      return events.pipe(map(() => undefined));
    }), /* The observable will complete after the first value */
    first());
  }
  /**
   * Deletes an item in our `indexedDB` store
   * @param key The item's key
   * @returns An RxJS `Observable` to wait the end of the operation
   */
  delete(key) {
    /* Open a transaction in write mode */
    return this.transaction("readwrite").pipe(mergeMap(transactionData => {
      const {
        store,
        events
      } = transactionData;
      /* Delete the item in store */
      store.delete(key);
      /* Listen to events and return `undefined` as no data is expected here */
      return events.pipe(map(() => undefined));
    }), /* The observable will complete after the first value */
    first());
  }
  /**
   * Deletes all items from our `indexedDB` objet store
   * @returns An RxJS `Observable` to wait the end of the operation
   */
  clear() {
    /* Open a transaction in write mode */
    return this.transaction("readwrite").pipe(mergeMap(transactionData => {
      const {
        store,
        events
      } = transactionData;
      /* Delete all items in object store */
      store.clear();
      /* Listen to events and return `undefined` as no data is expected here */
      return events.pipe(map(() => undefined));
    }), /* The observable will complete */
    first());
  }
  /**
   * Get all the keys in our `indexedDB` store
   * @returns An RxJS `Observable` iterating on each key
   */
  keys() {
    /* Open a transaction in read-only mode */
    return this.transaction("readonly").pipe(
    /* `first()` is used as the final operator in other methods to complete the `Observable`
     * (as it all starts from a `ReplaySubject` which never ends),
     * but as this method is iterating over multiple values, `first()` **must** be used here */
    first(), mergeMap(transactionData => {
      const {
        store
      } = transactionData;
      /* Open a cursor on the store
       * Avoid issues like https://github.com/cyrilletuzi/angular-async-local-storage/issues/69 */
      const request = store.openKeyCursor();
      /* Listen to success event */
      const success$ = fromEvent(request, "success").pipe( /* Stop the `Observable` when the cursor is `null` */
      // eslint-disable-next-line rxjs/no-ignored-takewhile-value -- Required by indexedDb behavior, getting the result from the event does not always work
      takeWhile(() => request.result !== null),
      /* This lib only allows string keys, but user could have added other types of keys from outside
       * It's OK to cast as the cursor as been tested in the previous operator */
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-base-to-string -- Required by indexedDb behavior, and strings are enforced by the lib
      map(() => request.result.key.toString()), /* Iterate on the cursor */
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Required by indexedDb behavior
      tap(() => {
        request.result.continue();
      }));
      /* Listen to error event and if so, throw an error */
      const error$ = this.listenError(request);
      /* Choose the first event to occur */
      return race([success$, error$]);
    }));
  }
  /**
   * Check if a key exists in our `indexedDB` store
   * @returns An RxJS `Observable` telling if the key exists or not
   */
  has(key) {
    /* Open a transaction in read-only mode */
    return this.transaction("readonly").pipe(mergeMap(transactionData => {
      const {
        store,
        events
      } = transactionData;
      /* Check if the key exists in the store
       * Fixes https://github.com/cyrilletuzi/angular-async-local-storage/issues/69
       */
      const request = store.getKey(key);
      /* Listen to events and return `true` or `false` */
      return events.pipe(map(() => request.result !== undefined ? true : false));
    }), /* The observable will complete */
    first());
  }
  /**
   * Connects to `indexedDB` and creates the object store on first time
   */
  connect() {
    let request;
    /* Connect to `indexedDB`
     * Will fail in Safari cross-origin iframes
     * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/issues/42} */
    try {
      /* Do NOT explicit `window` here, as `indexedDB` could be used from a web worker too */
      request = indexedDB.open(this.dbName, this.dbVersion);
    } catch {
      this.database.error(new IDBBrokenError());
      return;
    }
    /* Create store on first connection */
    this.createStore(request);
    /* Listen to success and error events */
    const success$ = fromEvent(request, "success");
    const error$ = this.listenError(request);
    /* Choose the first to occur */
    race([success$, error$])
    /* The observable will complete */.pipe(first()).subscribe({
      next: () => {
        /* Register the database connection in the `ReplaySubject` for further access */
        this.database.next(request.result);
      },
      error: () => {
        /* Keeping this error management for safety, but it should not happen anymore.
         * It was for Firefox private mode issue in Firefox versions < 115
         * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/issues/26} */
        this.database.error(new IDBBrokenError());
      }
    });
  }
  /**
   * Create store on first use of `indexedDB`
   * @param request `indexedDB` database opening request
   */
  createStore(request) {
    /* Listen to the event fired on first connection */
    fromEvent(request, "upgradeneeded")
    /* The observable will complete */.pipe(first()).subscribe({
      next: () => {
        /* Check if the store already exists, to avoid error */
        if (!request.result.objectStoreNames.contains(this.storeName)) {
          /* Create the object store */
          request.result.createObjectStore(this.storeName);
        }
      }
    });
  }
  /**
   * Open an `indexedDB` transaction and get our store
   * @param mode `readonly` or `readwrite`
   * @returns An `indexedDB` transaction store and events, wrapped in an RxJS `Observable`
   */
  transaction(mode) {
    /* From the `indexedDB` connection, open a transaction and get the store */
    return this.database.pipe(mergeMap(database => {
      let transaction;
      try {
        transaction = database.transaction([this.storeName], mode);
      } catch (error) {
        /* The store could have been deleted from outside */
        return throwError(() => error);
      }
      /* Get the store from the transaction */
      const store = transaction.objectStore(this.storeName);
      /* Listen transaction `complete` and `error` events */
      const events = this.listenTransactionEvents(transaction);
      return of({
        store,
        events
      });
    }));
  }
  /**
   * Listen errors on a transaction or request, and throw if trigerred
   * @param transactionOrRequest `indexedDb` transaction or request to listen
   * @returns An `Observable` listening to errors
   */
  listenError(transactionOrRequest) {
    return fromEvent(transactionOrRequest, "error").pipe(
    /* Throw on error to be able to catch errors in RxJS way.
     * Here `event.target` must be used, as `transactionOrRequest.error` will be `null`
     * if we are on the request and the error is only triggered later by the transaction */
    mergeMap(event => throwError(() => event.target?.error)));
  }
  /**
   * Listen transaction `complete` and `error` events
   * @param transaction Transaction to listen
   * @returns An `Observable` listening to transaction `complete` and `error` events
   */
  listenTransactionEvents(transaction) {
    /* Listen to the `complete` event */
    const complete$ = fromEvent(transaction, "complete");
    /* Listen to the `error` event */
    const error$ = this.listenError(transaction);
    /* Choose the first event to occur */
    return race([complete$, error$]);
  }
  static {
    this.ɵfac = function IndexedDBDatabase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || IndexedDBDatabase)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IndexedDBDatabase,
      factory: IndexedDBDatabase.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IndexedDBDatabase, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [], null);
})();
class JSONValidator {
  /**
   * Validate a JSON data against a Jsubset of the JSON Schema standard.
   * Types are enforced to validate everything: each schema must
   * @param data JSON data to validate
   * @param schema Subset of JSON Schema. Must have a `type`.
   * @returns If data is valid: `true`, if it is invalid: `false`
   * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/VALIDATION.md}
   */
  validate(data, schema) {
    switch (schema.type) {
      case "string":
        return this.validateString(data, schema);
      case "number":
      case "integer":
        return this.validateNumber(data, schema);
      case "boolean":
        return this.validateBoolean(data, schema);
      case "array":
        return this.validateArray(data, schema);
      case "object":
        return this.validateObject(data, schema);
    }
  }
  /**
   * Validate a string
   * @param data Data to validate
   * @param schema Schema describing the string
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateString(data, schema) {
    if (typeof data !== "string") {
      return false;
    }
    if (!this.validateConst(data, schema)) {
      return false;
    }
    if (!this.validateEnum(data, schema)) {
      return false;
    }
    if (schema.maxLength !== undefined && data.length > schema.maxLength) {
      return false;
    }
    if (schema.minLength !== undefined && data.length < schema.minLength) {
      return false;
    }
    if (schema.pattern) {
      try {
        const regularExpression = new RegExp(schema.pattern);
        if (!regularExpression.test(data)) {
          return false;
        }
      } catch {
        // Nothing to do
      }
    }
    return true;
  }
  /**
   * Validate a number or an integer
   * @param data Data to validate
   * @param schema Schema describing the number or integer
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateNumber(data, schema) {
    if (typeof data !== "number") {
      return false;
    }
    if (schema.type === "integer" && !Number.isInteger(data)) {
      return false;
    }
    if (!this.validateConst(data, schema)) {
      return false;
    }
    if (!this.validateEnum(data, schema)) {
      return false;
    }
    /* Test is done this way to not divide by 0 */
    if (schema.multipleOf && !Number.isInteger(data / schema.multipleOf)) {
      return false;
    }
    if (schema.maximum !== undefined && data > schema.maximum) {
      return false;
    }
    if (schema.exclusiveMaximum !== undefined && data >= schema.exclusiveMaximum) {
      return false;
    }
    if (schema.minimum !== undefined && data < schema.minimum) {
      return false;
    }
    if (schema.exclusiveMinimum !== undefined && data <= schema.exclusiveMinimum) {
      return false;
    }
    return true;
  }
  /**
   * Validate a boolean
   * @param data Data to validate
   * @param schema Schema describing the boolean
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateBoolean(data, schema) {
    if (typeof data !== "boolean") {
      return false;
    }
    if (!this.validateConst(data, schema)) {
      return false;
    }
    return true;
  }
  /**
   * Validate an array
   * @param data Data to validate
   * @param schema Schema describing the array
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateArray(data, schema) {
    if (!Array.isArray(data)) {
      return false;
    }
    if (schema.maxItems !== undefined && data.length > schema.maxItems) {
      return false;
    }
    if (schema.minItems !== undefined && data.length < schema.minItems) {
      return false;
    }
    if (schema.uniqueItems) {
      /* Create a set to eliminate values with multiple occurences */
      const dataSet = new Set(data);
      if (data.length !== dataSet.size) {
        return false;
      }
    }
    /* Specific test for tuples */
    if (Array.isArray(schema.items) || schema.items === undefined) {
      // TODO: cast should not be needed here
      return this.validateTuple(data, schema.items);
    }
    /* Validate all the values in array */
    for (const value of data) {
      // TODO: remove when TypeScript 4.1 is available
      // (currently the narrowed type from `Array.isArray()` is lost on readonly arrays)
      if (!this.validate(value, schema.items)) {
        return false;
      }
    }
    return true;
  }
  /**
   * Validate a tuple (array with fixed length and multiple types)
   * @param data Data to validate
   * @param schemas Schemas describing the tuple
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateTuple(data, schemas) {
    const lengthToCheck = schemas ? schemas.length : 0;
    /* Tuples have a fixed length */
    if (data.length !== lengthToCheck) {
      return false;
    }
    if (schemas) {
      for (const [index, schema] of schemas.entries()) {
        if (!this.validate(data[index], schema)) {
          return false;
        }
      }
    }
    return true;
  }
  /**
   * Validate an object
   * @param data Data to validate
   * @param schema JSON schema describing the object
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateObject(data, schema) {
    /* Check the type and if not `null` as `null` also have the type `object` in old browsers */
    if (typeof data !== "object" || data === null) {
      return false;
    }
    /* Check if the object doesn't have more properties than expected
     * Equivalent of `additionalProperties: false`
     */
    if (Object.keys(schema.properties).length < Object.keys(data).length) {
      return false;
    }
    /* Validate required properties */
    if (schema.required) {
      for (const requiredProp of schema.required) {
        if (!Object.hasOwn(data, requiredProp)) {
          return false;
        }
      }
    }
    /* Recursively validate all properties */
    for (const property in schema.properties) {
      /* Filter to keep only real properties (no internal JS stuff) and check if the data has the property too */
      if (Object.hasOwn(schema.properties, property) && Object.hasOwn(data, property)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Ensured by the logic
        if (!this.validate(data[property], schema.properties[property])) {
          return false;
        }
      }
    }
    return true;
  }
  /**
   * Validate a constant
   * @param data Data ta validate
   * @param schema JSON schema describing the constant
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateConst(data, schema) {
    if (!schema.const) {
      return true;
    }
    return data === schema.const;
  }
  /**
   * Validate an enum
   * @param data Data ta validate
   * @param schema JSON schema describing the enum
   * @returns If data is valid: `true`, if it is invalid: `false`
   */
  validateEnum(data, schema) {
    if (!schema.enum) {
      return true;
    }
    /* Cast as the data can be of multiple types, and so TypeScript is lost */
    return schema.enum.includes(data);
  }
  static {
    this.ɵfac = function JSONValidator_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JSONValidator)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: JSONValidator,
      factory: JSONValidator.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JSONValidator, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], null, null);
})();
class StorageMap {
  #database;
  #jsonValidator;
  #notifiers = new Map();
  /**
   * Constructor params are provided by Angular (but can also be passed manually in tests)
   * @param database Storage to use
   */
  constructor(database) {
    this.#database = database;
    this.#jsonValidator = new JSONValidator();
  }
  /**
   * **Number of items** in storage, wrapped in an Observable.
   *
   * Note you do *not* need to unsubscribe (it is a self-completing Observable).
   *
   * @example
   * this.storageMap.size.subscribe((size) => {
   *   console.log(size);
   * });
   */
  get size() {
    return this.#database.size
    /* Catch if `indexedDb` is broken */.pipe(this.#catchIDBBroken(() => this.#database.size));
  }
  /**
   * Tells you which storage engine is used.
   *
   * *Only useful for interoperability.*
   *
   * Note that due to some browsers issues in some special contexts
   * (like Safari cross-origin iframes),
   * **this information may be wrong at initialization,**
   * as the storage could fallback from `indexedDB` to `localStorage`
   * only after a first read or write operation.
   * @returns Storage engine used
   *
   * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/INTEROPERABILITY.md}
   *
   * @example
   * if (this.storageMap.backingEngine === 'indexedDB') {}
   */
  get backingEngine() {
    if (this.#database instanceof IndexedDBDatabase) {
      return "indexedDB";
    } else if (this.#database instanceof LocalStorageDatabase) {
      return "localStorage";
    } else if (this.#database instanceof MemoryDatabase) {
      return "memory";
    } else {
      return "unknown";
    }
  }
  /**
   * Information about `indexedDB` database.
   *
   * *Only useful for interoperability.*
   *
   * @returns `indexedDB` database name, store name and database version.
   * **Values will be empty if the storage is not `indexedDB`, so it should be used after an engine check**.
   *
   * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/INTEROPERABILITY.md}
   *
   * @example
   * if (this.storageMap.backingEngine === 'indexedDB') {
   *   const { database, store, version } = this.storageMap.backingStore;
   * }
   */
  get backingStore() {
    return this.#database instanceof IndexedDBDatabase ? this.#database.backingStore : {
      database: "",
      store: "",
      version: 0
    };
  }
  /**
   * Information about `localStorage` fallback storage.
   *
   * *Only useful for interoperability.*
   *
   * @returns `localStorage` prefix.
   * **Values will be empty if the storage is not `localStorage`, so it should be used after an engine check**.
   *
   * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/INTEROPERABILITY.md}
   *
   * @example
   * if (this.storageMap.backingEngine === 'localStorage') {
   *   const { prefix } = this.storageMap.fallbackBackingStore;
   * }
   */
  get fallbackBackingStore() {
    return this.#database instanceof LocalStorageDatabase ? {
      prefix: this.#database.prefix
    } : {
      prefix: ""
    };
  }
  get(key, schema) {
    /* Get the data in storage */
    return this.#database.get(key).pipe( /* Check if `indexedDb` is broken */
    this.#catchIDBBroken(() => this.#database.get(key)), mergeMap(data => {
      /* No need to validate if the data is empty */
      if (data === undefined || data === null) {
        return of(undefined);
      } else if (schema) {
        /* Validate data against a JSON schema if provided */
        if (!this.#jsonValidator.validate(data, schema)) {
          return throwError(() => new ValidationError());
        }
        /* Data have been checked, so it's OK to cast */
        return of(data);
      }
      /* Cast to unknown as the data wasn't checked */
      return of(data);
    }));
  }
  /**
   * Store an item in storage.
   *
   * Note that:
   * * you *do* need to subscribe, even if you do not have something specific to do after writing in storage, otherwise nothing happens (because it is how RxJS Observables work),
   * * but you do *not* need to unsubscribe (it is a self-completing Observable),
   * * setting `null` or `undefined` will remove the item to avoid some browsers issues,
   * * you should stick to serializable JSON data, meaning primitive types, arrays and literal objects. Date, Map, Set, Blob and other special structures can cause issues in some scenarios.
   * @see {@link https://github.com/cyrilletuzi/angular-async-local-storage/blob/main/docs/SERIALIZATION.md}
   *
   * @param key The item's key
   * @param data The item's value
   * @param schema Optional JSON schema to validate the data
   * @returns A RxJS Observable to wait the end of the operation
   *
   * @example
   * this.storageMap.set('key', 'value').subscribe(() => {});
   */
  set(key, data, schema) {
    /* Storing `undefined` or `null` is useless and can cause issues in `indexedDb` in some browsers,
     * so removing item instead for all storages to have a consistent API */
    if (data === undefined || data === null) {
      return this.delete(key);
    }
    /* Validate data against a JSON schema if provided */
    if (schema && !this.#jsonValidator.validate(data, schema)) {
      return throwError(() => new ValidationError());
    }
    return this.#database.set(key, data).pipe( /* Catch if `indexedDb` is broken */
    this.#catchIDBBroken(() => this.#database.set(key, data)), /* Notify watchers (must be last because it should only happen if the operation succeeds) */
    tap(() => {
      this.#notify(key, data);
    }));
  }
  /**
   * Delete an item in storage.
   *
   * Note that:
   * * you *do* need to subscribe, even if you do not have something specific to do after deleting, otherwise nothing happens (because it is how RxJS Observables work),
   * * but you do *not* need to unsubscribe (it is a self-completing Observable).
   *
   * @param key The item's key
   * @returns A RxJS Observable to wait the end of the operation
   *
   * @example
   * this.storageMap.delete('key').subscribe(() => {});
   */
  delete(key) {
    return this.#database.delete(key).pipe( /* Catch if `indexedDb` is broken */
    this.#catchIDBBroken(() => this.#database.delete(key)), /* Notify watchers (must be last because it should only happen if the operation succeeds) */
    tap(() => {
      this.#notify(key, undefined);
    }));
  }
  /**
   * Delete all items in storage.
   *
   * Note that:
   * * you *do* need to subscribe, even if you do not have something specific to do after clearing, otherwise nothing happens (because it is how RxJS Observables work),
   * * but you do *not* need to unsubscribe (it is a self-completing Observable).
   *
   * @returns A RxJS Observable to wait the end of the operation
   *
   * @example
   * this.storageMap.clear().subscribe(() => {});
   */
  clear() {
    return this.#database.clear().pipe( /* Catch if `indexedDb` is broken */
    this.#catchIDBBroken(() => this.#database.clear()), /* Notify watchers (must be last because it should only happen if the operation succeeds) */
    tap(() => {
      for (const key of this.#notifiers.keys()) {
        this.#notify(key, undefined);
      }
    }));
  }
  /**
   * Get all keys stored in storage.
   *
   * Note **this is an *iterating* Observable**:
   * * if there is no key, the `next` callback will not be invoked,
   * * if you need to wait the whole operation to end, be sure to act in the `complete` callback,
   * as this Observable can emit several values and so will invoke the `next` callback several times,
   * * you do *not* need to unsubscribe (it is a self-completing Observable).
   *
   * @returns A list of the keys wrapped in a RxJS Observable
   *
   * @example
   * this.storageMap.keys().subscribe({
   *   next: (key) => { console.log(key); },
   *   complete: () => { console.log('Done'); },
   * });
   */
  keys() {
    return this.#database.keys()
    /* Catch if `indexedDb` is broken */.pipe(this.#catchIDBBroken(() => this.#database.keys()));
  }
  /**
   * Tells if a key exists in storage.
   *
   * Note you do *not* need to unsubscribe (it is a self-completing Observable).
   *
   * @returns A RxJS Observable telling if the key exists
   *
   * @example
   * this.storageMap.has('key').subscribe((hasKey) => {
   *   if (hasKey) {}
   * });
   */
  has(key) {
    return this.#database.has(key)
    /* Catch if `indexedDb` is broken */.pipe(this.#catchIDBBroken(() => this.#database.has(key)));
  }
  watch(key, schema) {
    /* Check if there is already a notifier */
    if (!this.#notifiers.has(key)) {
      this.#notifiers.set(key, new ReplaySubject(1));
    }
    /* Non-null assertion is required because TypeScript doesn't narrow `.has()` yet */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Ensured by the logic
    const notifier = this.#notifiers.get(key);
    /* Get the current item value */
    (schema ? this.get(key, schema) : this.get(key)).subscribe({
      next: result => {
        notifier.next(result);
      },
      error: error => {
        notifier.error(error);
      }
    });
    /* Only the public API of the Observable should be returned */
    return schema ? notifier.asObservable() : notifier.asObservable();
  }
  /**
   * Notify when a value changes
   * @param key The item's key
   * @param data The new value
   */
  #notify(key, value) {
    const notifier = this.#notifiers.get(key);
    if (notifier) {
      notifier.next(value);
    }
  }
  /**
   * RxJS operator to catch if `indexedDB` is broken
   * @param operationCallback Callback with the operation to redo
   */
  #catchIDBBroken(operationCallback) {
    return catchError(error => {
      /* Check if `indexedDB` is broken based on error message (the specific error class seems to be lost in the process) */
      if (error !== undefined && error !== null && typeof error === "object" && "message" in error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Required because TypeScript narrowing is not working here
      && error.message === IDB_BROKEN_ERROR) {
        /* When storage is fully disabled in browser (via the "Block all cookies" option),
         * just trying to check `localStorage` variable causes a security exception.
         * Prevents https://github.com/cyrilletuzi/angular-async-local-storage/issues/118
         */
        try {
          if ("getItem" in localStorage) {
            /* Fallback to `localStorage` if available */
            this.#database = new LocalStorageDatabase();
          } else {
            /* Fallback to memory storage otherwise */
            this.#database = new MemoryDatabase();
          }
        } catch {
          /* Fallback to memory storage otherwise */
          this.#database = new MemoryDatabase();
        }
        /* Redo the operation */
        return operationCallback();
      } else {
        /* Otherwise, rethrow the error */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return throwError(() => error);
      }
    });
  }
  /**
   * THIS METHOD IS FOR INTERNAL PURPOSE ONLY AND MUST NOT BE USED,
   * IT CAN BE REMOVED AT ANY TIME AND MESSING WITH IT CAN CAUSE ISSUES
   * @private
   * @ignore
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error -- Silence the not used error, it is used in tests
  // @ts-ignore
  ɵinternalGetDatabase() {
    return this.#database;
  }
  static {
    this.ɵfac = function StorageMap_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StorageMap)(i0.ɵɵinject(LocalDatabase));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StorageMap,
      factory: StorageMap.ɵfac,
      providedIn: "root"
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StorageMap, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: LocalDatabase
  }], null);
})();

/*
 * Public API Surface of local-storage
 */

/**
 * Generated bundle index. Do not edit.
 */

export { SERIALIZATION_ERROR, SerializationError, StorageMap, StorageModule, VALIDATION_ERROR, ValidationError, provideIndexedDBDataBaseName, provideIndexedDBDataBaseVersion, provideIndexedDBStoreName, provideLocalStoragePrefix };
