import {ActivatedRouteSnapshot, DefaultUrlSerializer, NavigationExtras, Router, UrlSegment, UrlTree} from '@angular/router';


export class RoutingHelpers {

  static getUrlTree(router: Router): UrlTree {
    return router.parseUrl(router.url);
  }

  /**
   * Extrahiert aus dem übergebenen Parameter die pathSegments ohne GET-Paramter als string-Array.
   *
   * So wird z.B für eine URL '/foo/bar?x=a&y=b' das array ['foo', 'bar'] zurückgegeben.
   *
   * Siehe auch README.md: "BerechtigungService" und "TaskService"
   */
  static getPathSegments(segments: UrlSegment[] | UrlTree | Router | ActivatedRouteSnapshot | string): string[] {
    if (segments instanceof ActivatedRouteSnapshot) {
      return RoutingHelpers.getPathSegmentsFromActivatedRouteSnapshot(segments);
    }

    if (segments instanceof Router) {
      return RoutingHelpers.getPathSegments(RoutingHelpers.getUrlTree(segments));
    }

    if (segments instanceof UrlTree) {
      if (segments.root.children?.['primary']) {
        return this.getPathSegments(segments.root.children['primary'].segments);
      } else {
        // root path
        return [];
      }
    }

    if (typeof segments === 'string') {
      return RoutingHelpers.getPathSegments(new DefaultUrlSerializer().parse(segments));
    }

    const pathSegments: string[] = [];
    if (segments && segments.length > 0) {
      for (const segment of segments) {
        pathSegments.push(segment.path);
      }
    }
    return pathSegments;
  }

  static getRouterPath(segments: string[], asRootPath: boolean = true): string {
    let path: string;

    if (asRootPath) {
      path = '/';
    } else {
      path = '';
    }

    if (!segments || segments.length === 0) {
      return path;
    } else {
      for (const segment of segments) {
        path += segment + '/';
      }
      return path.substring(0, path.length - 1);
    }
  }

  /**
   * geht rekursiv durch den übergebenen routeSnapshout und sammelt die path-segments auf.
   * @param route
   */
  static getPathSegmentsFromActivatedRouteSnapshot(route: ActivatedRouteSnapshot): string[] {
    if (route === null || route.parent === null) {
      return [];
    }

    const segments: string[] = this.getPathSegmentsFromActivatedRouteSnapshot(route.parent);
    if (route.url && route.url.length > 0) {

      for (const segment of route.url) {
        if (segment.path) {
          segments.push(segment.path);
        }
      }
    }
    return segments;
  }

  /**
   * filtert aus der URL ev. parameter heraus.
   * @param url
   */
  static removeUrlParams(url: string): string {
    const i = url.indexOf('?');
    if (i > 0) {
      return url.substring(0, i);
    } else {
      return url;
    }
  }

  /**
   * Hilfs-Funktion, die immer dann verwendet werden sollte, wenn bestehende get-Parameter weitergegeben
   * werden sollen (z.B. wenn ein Task durchlaufen werden soll).
   *
   * @param skipLocationChange siehe https://angular.io/api/router/NavigationExtras#skipLocationChange
   *       (tl;dr: Weiterleitung soll nicht in die Browser-History, benötigt z.B. für Browser back).
   */
  static navigateWithUrlParams(url: string | string[], router: Router,
                               skipLocationChange: boolean = false): Promise<boolean> {
    if (url instanceof Array) {
      return RoutingHelpers.navigateWithUrlParams(RoutingHelpers.getRouterPath(url, true), router, skipLocationChange);
    }

    const navigationExtras: NavigationExtras = {};
    if (skipLocationChange) {
      navigationExtras.skipLocationChange = true;
    }

    return router.navigateByUrl(
      router.createUrlTree([url], {queryParamsHandling: 'merge'}),
      navigationExtras,
    );
  }

  // static addQueryParam(key: string, value: string, params: any = {}): any {
  //   params[key] = value;
  //   return params;
  // }

}
