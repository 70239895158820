import {Component, Input} from '@angular/core';
import {MatCard, MatCardHeader, MatCardTitle} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'app-card-warning',
  templateUrl: './card-warning.component.html',
  styleUrl: './card-warning.component.scss',
  standalone: true,
  imports: [
    MatIcon,
    MatCardTitle,
    MatCardHeader,
    MatCard,
  ],
})
export class CardWarningComponent {
  @Input() text!: string;
}
