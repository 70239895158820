/* eslint-disable @typescript-eslint/no-namespace */
import {RoutingHelpers} from './routing-helpers';

export namespace RoutingPath {

  // stammtisch
  export const stammtisch: string = 'stammtisch';
  export namespace Stammtisch {
    export const segments: string[] = [stammtisch];
    export const rootPath: string = RoutingHelpers.getRouterPath(Stammtisch.segments);

    export const par_id: string = 'id';
    export const id: string = ':' + par_id;

    // achievements
    export const achievements: string = 'achievements';
    export namespace Achievements {
      export function segments(stammtischId: number): string[] {
        return [stammtisch, stammtischId.toString() + '', achievements];
      }

      export function rootPath(stammtischId: number): string {
        return RoutingHelpers.getRouterPath(Achievements.segments(stammtischId));
      }
    }

    // drinks
    export const drinks: string = 'drinks';
    export namespace Drinks {
      export function segments(stammtischId: number): string[] {
        return [stammtisch, stammtischId.toString() + '', drinks];
      }

      export function rootPath(stammtischId: number): string {
        return RoutingHelpers.getRouterPath(Drinks.segments(stammtischId));
      }
    }

    // drunks
    export const drunks: string = 'drunks';
    export namespace Drunks {
      export function segments(stammtischId: number): string[] {
        return [stammtisch, stammtischId.toString() + '', drunks];
      }

      export function rootPath(stammtischId: number): string {
        return RoutingHelpers.getRouterPath(Drunks.segments(stammtischId));
      }
    }

    // members
    export const members: string = 'members';
    export namespace Members {
      export function segments(stammtischId: number): string[] {
        return [stammtisch, stammtischId.toString() + '', members];
      }

      export function rootPath(stammtischId: number): string {
        return RoutingHelpers.getRouterPath(Members.segments(stammtischId));
      }
    }

    // payments
    export const payments: string = 'payments';
    export namespace Payments {
      export const history: string = 'history';
      export namespace History {
        export function segments(stammtischId: number): string[] {
          return [stammtisch, stammtischId.toString() + '', payments, history];
        }

        export function rootPath(stammtischId: number): string {
          return RoutingHelpers.getRouterPath(History.segments(stammtischId));
        }
      }

      export function segments(stammtischId: number): string[] {
        return [stammtisch, stammtischId.toString() + '', payments];
      }

      export function rootPath(stammtischId: number): string {
        return RoutingHelpers.getRouterPath(Payments.segments(stammtischId));
      }
    }

    // payments
    export const history: string = 'history';
    export namespace History {
      export function segments(stammtischId: number): string[] {
        return [stammtisch, stammtischId.toString() + '', history];
      }

      export function rootPath(stammtischId: number): string {
        return RoutingHelpers.getRouterPath(History.segments(stammtischId));
      }
    }
  }

  // Login
  export const login: string = 'login';
  export namespace Login {
    export const segments: string[] = [login];
    export const rootPath: string = RoutingHelpers.getRouterPath(Login.segments);
  }

  // Logout
  export const logout: string = 'logout';
  export namespace Logout {
    export const segments: string[] = [logout];
    export const rootPath: string = RoutingHelpers.getRouterPath(Logout.segments);
  }

  // Register
  export const register: string = 'register';
  export namespace Register {
    export const segments: string[] = [register];
    export const rootPath: string = RoutingHelpers.getRouterPath(Register.segments);

    // verify with token
    export const par_token: string = 'token';
    export const token: string = ':' + par_token;
  }

  // Passwort setzen
  export const passwort: string = 'passwort';
  export namespace Passwort {
    export const segments: string[] = [passwort];
    export const rootPath: string = RoutingHelpers.getRouterPath(Passwort.segments);

    // verify with token
    export const par_token: string = Register.par_token;
    export const token: string = ':' + par_token;
  }

  // Passwort zurücksetzen
  export const resetPasswort: string = 'resetPasswort';
  export namespace ResetPassword {
    export const segments: string[] = [resetPasswort];
    export const rootPath: string = RoutingHelpers.getRouterPath(ResetPassword.segments);
  }

  // Error
  export const error: string = 'error';
  export namespace Error {
    export const segments: string[] = [error];
    export const rootPath: string = RoutingHelpers.getRouterPath(Error.segments);
  }


  export const forbidden: string = '403';
  export const notFound: string = '404';
}
