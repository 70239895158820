import {TooltipItem} from 'chart.js';

export const formatLineTooltipItemToCurrency = (context: TooltipItem<'line'>): string => {
  const label: string = context.dataset.label ?? '';
  if (label === '') {
    return '';
  }
  return label + ': ' + context.parsed.y.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '€';
};

export const formatPieTooltipItemToCurrency = (context: TooltipItem<'pie'>): string => {
  const label: string = context.dataset.label ?? '';
  if (label === '') {
    return '';
  }
  return label + ': ' + context.parsed.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '€';
};
