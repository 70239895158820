/* eslint-disable @typescript-eslint/no-floating-promises */

import {Injectable} from '@angular/core';
import axios from 'axios';
import {BehaviorSubject, Observable} from 'rxjs';

import {Drink} from '../model/drink.model';
import {Drunk} from '../model/drunk.model';
import {Member} from '../model/member.model';
import {Payment} from '../model/payment.model';
import {StammtischSettings} from '../model/stammtisch-settings.model';
import {Stammtisch} from '../model/stammtisch.model';
import {LoginService} from '../noauth/login/login.service';
import {AppShellService} from './app-shell.service';


axios.defaults.baseURL = 'https://api.stammtisch.app/0.1';

@Injectable({
  providedIn: 'root',
})
export class StammtischsService {
  private _currentUserID: number = 0;
  private _selectedStammtischID: number = 0;
  private stammtischSubject = new BehaviorSubject<Stammtisch | undefined>(undefined);
  private stammtisch$ = this.stammtischSubject.asObservable();
  private stammtischsSubject = new BehaviorSubject<Stammtisch[]>([]);
  private stammtischs$ = this.stammtischsSubject.asObservable();
  private drinksSubject = new BehaviorSubject<Drink[]>([]);
  private drinks$ = this.drinksSubject.asObservable();
  private drunksSubject = new BehaviorSubject<Drunk[]>([]);
  private drunks$ = this.drunksSubject.asObservable();
  private memberSubject = new BehaviorSubject<Member | undefined>(undefined);
  private member$ = this.memberSubject.asObservable();
  private membersSubject = new BehaviorSubject<Member[]>([]);
  private members$ = this.membersSubject.asObservable();
  private paymentsSubject = new BehaviorSubject<Payment[]>([]);
  private payments$ = this.paymentsSubject.asObservable();

  constructor(
    private loginService: LoginService,
    private appShellService: AppShellService,
  ) {
    this.loginService.currentUser$.subscribe((currentUser) => {
      if (currentUser && currentUser.key) {
        this._currentUserID = currentUser.user.id;
        axios.defaults.headers.common['x-auth-key'] = `${currentUser.key}`;
      }
    });

    this.getStammtisch().subscribe((stammtisch) => {
      this.appShellService.setStammtisch(stammtisch);
    });
    this.membersSubject.subscribe((members) => {
      members.forEach((member) => {
        if (member.id_user === this._currentUserID) {
          this.memberSubject.next(member);
        }
      });
    });
  }

  set selectedStammtischID(value: number) {
    if (this._selectedStammtischID === value) {
      this.pullStammtisch();
      return;
    }
    this._selectedStammtischID = value;
    this.pullStammtisch();
    this.pullDrinks();
    this.pullDrunks(); // todo
    this.pullPayments(); // todo
    this.pullMembers(); // todo
  }

  get selectedStammtischID() {
    return this._selectedStammtischID;
  }

  getStammtischs(): Observable<Stammtisch[]> {
    this.pullStammtischs();
    return this.stammtischs$;
  }

  pullStammtischs(): void {
    axios.get(
      '/stammtischs',
    ).then((response) => {
      this.stammtischsSubject.next(response.data as Stammtisch[]);
    });
  }

  getStammtisch(): Observable<Stammtisch | undefined> {
    return this.stammtisch$;
  }

  getDrinks(): Observable<Drink[]> {
    return this.drinks$;
  }

  pullDrinks() {
    axios.get(
      '/stammtischs/' + this.selectedStammtischID.toString() + '/drinks',
    ).then((response) => {
      this.drinksSubject.next(response.data as Drink[]);
      this.matchDrinkDrunk();
    });
  }

  getDrunks(): Observable<Drunk[]> {
    return this.drunks$;
  }

  pullDrunks(): void {
    axios.get(
      '/stammtischs/' + this.selectedStammtischID.toString() + '/drunks',
    ).then((response) => {
      this.drunksSubject.next(response.data as Drunk[]);
      this.matchDrinkDrunk();
    });
  }

  matchDrinkDrunk(): void {
    const drinks = this.drinksSubject.value;
    const drunks = this.drunksSubject.value;
    if (drinks.length !== 0) {
      drunks.forEach((drunk) => {
        drunk.drink = drinks.find((drink) => {
          return drink.id === drunk.id_drink;
        });
      });
    }
    this.drunksSubject.next(drunks);
  }

  getMember(): Observable<Member | undefined> {
    return this.member$;
  }

  getMembers(): Observable<Member[]> {
    return this.members$;
  }

  pullMembers(): void {
    axios.get(
      '/stammtischs/' + this.selectedStammtischID.toString() + '/members',
    ).then((response) => {
      this.membersSubject.next(response.data as Member[]);
    });
  }

  pullStammtisch(): void {
    axios.get(
      '/stammtischs/' + this.selectedStammtischID.toString(),
    ).then((response) => {
      this.stammtischSubject.next(response.data as Stammtisch);
    });
  }

  getPayments(): Observable<Payment[]> {
    return this.payments$;
  }

  pullPayments(): void {
    axios.get(
      '/stammtischs/' + this.selectedStammtischID.toString() + '/payments',
    ).then((response) => {
      this.paymentsSubject.next(response.data as Payment[]);
    });
  }

  // getStammtischById(stammtisch_id: number): Observable<Stammtisch> {
  // }

  // getDrunksByStammtisch(stammtisch_id: number): Observable<Drunk[]> {
  // }

  // getMembersByStammtisch(stammtisch_id: number): Observable<Member[]> {
  // }

  // getPaymentsByStammtisch(stammtisch_id: number): Observable<Payment[]> {
  // }

  createOrUpdateDrink(drink: Drink) {
    const result = this.createDrinkByStammtisch(drink.id_stammtisch, drink);
    result.then(() => {
      this.pullDrinks();
    });
    return result;
  }

  createDrinkByStammtisch(stammtisch_id: number, drink: Drink) {
    const update: boolean = drink.id > 0;
    if (update) {
      const body = {
        'description': drink.description,
        'price': drink.price,
        'size': drink.size,
      };
      return axios.patch(
        '/stammtischs/' + stammtisch_id.toString() + '/drinks/' + drink.id.toString(),
        body,
      );
    }
    // create
    const body = {
      'name': drink.name,
      'description': drink.description,
      'price': drink.price,
      'size': drink.size,
    };
    return axios.post(
      '/stammtischs/' + stammtisch_id.toString() + '/drinks',
      body,
    );
  }

  createDrunkByStammtisch(stammtisch_id: number, drunk_id: number) {
    const body = {'id_drink': drunk_id};
    const result = axios.post(
      '/stammtischs/' + stammtisch_id.toString() + '/drunks',
      body,
    );
    result.then(() => {
      this.pullDrunks();
      this.pullMembers();
    });
    return result;
  }

  createPaymentByStammtisch(stammtisch_id: number, amount: number) {
    const body = {'price': amount};
    const result = axios.post(
      '/stammtischs/' + stammtisch_id.toString() + '/payments',
      body,
    );
    result.then(() => {
      this.pullPayments();
      this.pullMembers();
    });
    return result;
  }

  deleteDrink(drink: Drink) {
    const result = axios.delete(
      '/stammtischs/' + drink.id_stammtisch.toString() + '/drinks/' + drink.id.toString(),
    );
    result.then(() => {
      this.pullDrinks();
    });
    return result;
  }

  // ToDo generalize
  saveStammtischSettings(settings: StammtischSettings) {
    const body = {
      settings: settings,
    };
    const result = axios.patch(
      '/stammtischs/' + this._selectedStammtischID.toString(),
      body,
    );
    result.then(() => {
      this.pullStammtisch();
    });
    return result;
  }

  doNotifyMembersOpening() {
    const body = {
      action: 'opening',
    };
    return axios.post(
      '/stammtischs/' + this._selectedStammtischID.toString() + '/webpush',
      body,
    );
  }

  doNotifyMembersClosing() {
    const body = {
      action: 'closing',
    };
    return axios.post(
      '/stammtischs/' + this._selectedStammtischID.toString() + '/webpush',
      body,
    );
  }
}
