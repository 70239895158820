import {Injectable, TemplateRef} from '@angular/core';
import {MatDrawerMode} from '@angular/material/sidenav';
import {BehaviorSubject, Observable} from 'rxjs';
import {Stammtisch} from '../model/stammtisch.model';

@Injectable({
  providedIn: 'root',
})
export class AppShellService {
  showSideNav: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentSideNavMode: BehaviorSubject<MatDrawerMode> = new BehaviorSubject<MatDrawerMode>('over');
  stammtisch?: Stammtisch;
  private readonly backButtonSubject = new BehaviorSubject<string | null>(null);
  private readonly moreMenuSubject = new BehaviorSubject<TemplateRef<void> | null>(null);

  get backButton() {
    return this.backButtonSubject;
  }

  get moreMenu() {
    return this.moreMenuSubject;
  }

  /* TODO */
  setStammtisch(stammtisch?: Stammtisch): void {
    this.stammtisch = stammtisch;
  }

  sideNavClose(): void {
    this.showSideNav.next(false);
  }

  sideNavOpen(): void {
    this.showSideNav.next(true);
  }

  sideNavToggle(): void {
    this.showSideNav.next(!this.showSideNav.getValue());
  }

  get showSideNav$(): Observable<boolean> {
    return this.showSideNav.asObservable();
  }

  get currentSideNavMode$(): Observable<MatDrawerMode> {
    return this.currentSideNavMode.asObservable();
  }

  get isMobile(): boolean {
    return this.currentSideNavMode.value == 'side';
  }

  setDesktop(): void {
    this.showSideNav.next(true);
    this.currentSideNavMode.next('side');
  }

  setMobile(): void {
    this.showSideNav.next(false);
    this.currentSideNavMode.next('over');
  }

  constructor() {
    this.showSideNav.subscribe(
      (value) => console.debug('Change sideNav to ' + String(value)),
    );
  }

  // reset if navigation
  reset(): void {
    this.backButton.next(null);
    this.moreMenu.next(null);
  }
}
